<template>
  <section class="">
    <div class="column is-offset-3 is-6" style="padding-bottom: 20px">
      <div class="is-size-4">
        {{ title }}
      </div>
      <div>
        <div v-html="formattedDescription"></div>
      </div>
    </div>
    <div v-if="optional" class="column is-offset-3" style="padding-bottom: 20px">
      <b-switch v-model="noImage" @input="$emit('sem-imagem', noImage)">
        {{ `Não possuo ${title}` }}
      </b-switch>
    </div>
    <form class="column" @submit.prevent="validate()">
      <div class="columns is-centered">
        <div class="image-file is-narrow">
          <figure class="imagem">
            <img v-if="!imagem" :src="require(`../../../assets/${imagemExibicao}`)">
            <img v-if="imageB64" :src="imageB64">
          </figure>
          <object
            v-if="pdfB64"
            type="application/pdf"
            :data="pdfB64"
            style="width: 500px; height:500px"
          />
        </div>
      </div>
      <div class="columns is-centered">
        <b-field
          :type="fieldType"
          :message="message"
          class="file column is-narrow"
        >
        <span class="error file column is-narrow" v-if="fileError">
          Arquivo deve ter no máximo 10mb
        </span>
          <b-upload
            v-model="file"
            accept=".jpg, .png, .pdf, .jpeg"
            @input="setImage"
          >
            <a class="button is-primary">
              <b-icon icon="upload" />
              <span>Carregar Imagem</span>
            </a>
          </b-upload>
          <span v-if="file" class="file-name">
            {{ file.name }}
          </span>
        </b-field>
      </div>
    </form>
  </section>
</template>
<script>
export default {
  name: 'UploadImage',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    imagemExibicao: {
      type: String,
      required: true
    },
    optional: {
      type: Boolean,
      required: false,
      default: false
    },
    imagem: {
      type: null,
      required: true
    },
    tipoRecursoSolicitacao: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      file: null,
      fileError: false,
      imageB64: null,
      pdfB64: null,
      noImage: false,
      message: '',
      fieldType: ''
    }
  },
  computed: {
    formattedDescription () {
      if (this.tipoRecursoSolicitacao === 'A') {
        return '<b>' + this.description + '</b>'
      } else {
        return this.description
      }
    }
  },
  beforeMount () {
    if (this.imagem) {
      this.file = this.imagem
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        if ((this.imagem && !this.fileError) || (this.optional && this.noImage)) {
          this.fieldType = ''
          this.message = ''
          resolve(true)
        } else {
          this.fieldType = 'is-danger'
          this.message = `${this.title} é necessário`
        }
      })
    },
    setImage (file) {
      if (file.size > 10000000) { // Imagem deve ter no máximo 10mb
        this.fileError = true
        this.file = null
        return
      }

      this.fileError = false
      this.imageB64 = window.URL.createObjectURL(file)
      this.pdfB64 = null
      this.fieldType = ''
      this.message = ''

      if (file.type === 'application/pdf') {
        this.pdfB64 = window.URL.createObjectURL(file)
        this.imageB64 = null
      }

      this.$emit('atualiza-imagem', file)
    }
  }
}
</script>

<style>
  .imagem {
    max-width: 256px;
    max-height: 512px;
  }

  .error {
    color: #d11313;
    font-size: 15px;
  }
</style>
